// Old a1 web stats code
window._pt = ["04D22EAE-11A5-4DB0-A014-BDDF77DB322B"];
(function (d, t) {
    var a = d.createElement(t),
        s = d.getElementsByTagName(t)[0];a.src = location.protocol + '//a1webstrategy.com/track.js';s.parentNode.insertBefore(a, s);
})(document, 'script');

(function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;i[r] = i[r] || function () {
        (i[r].q = i[r].q || []).push(arguments);
    }, i[r].l = 1 * new Date();a = s.createElement(o), m = s.getElementsByTagName(o)[0];a.async = 1;a.src = g;m.parentNode.insertBefore(a, m);
})(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

ga('create', 'UA-70153256-1', 'auto');
ga('send', 'pageview');

// New a1 web stats code
var cid = 6037;
(function () {
    window.a1WebStatsObj = 'a1w';
    window.a1w = window.a1w || function () {
        (window.a1w.q = window.ga.q || []).push(arguments);
    }, window.a1w.l = 1 * new Date();
    var a = document.createElement('script');
    var m = document.getElementsByTagName('script')[0];
    a.async = 1;
    a.src = "https://api1.websuccess-data.com/tracker.js";
    m.parentNode.insertBefore(a, m);
})();