(function () {
    /* jshint laxcomma */
    /* global $ */
    "use strict";

    $("#client-logos").mThumbnailScroller({
        axis: "x",
        type: "hover-precise"
    });

    $("#client-logos").css({
        "overflow": "hidden"
    });

    $(".navbar-toggle.collapsed").on("click", function (e) {
        e.preventDefault();

        $("#navbar").slideToggle();
    });

    $('.blog--post-content a').not('[href^="http://dsear"]').not('[href^="http://www.dsear"]').not('[href^="http://localhost"]').not('[href^="/"]').attr('target', '_blank');
})();